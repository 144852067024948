import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import model from './BottomMenu.model';

const BottomMenu = () => {
  const [socialDataArr, companyDataArr, productDataArr, solutionsDataArr] = [
    model.social,
    model.company,
    model.product,
    model.solutions,
  ];

  const socialMediaIconsNav = socialDataArr.map((icon, index) => (
    <div key={index} className='company__icon'>
      <Link href={icon.url}>
        <a
          aria-label={icon.anchor}
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <FontAwesomeIcon icon={icon.icon} className='company__item-icon' />
        </a>
      </Link>
    </div>
  ));

  const companyPagesNav = companyDataArr.map((page, index) => (
    <div key={index} className='company__item bottom-menu__list-item'>
      <a
        href={page.url}
        className='company__item-text bottom-menu__list-item-link'
        target={`${page.isExternal ? '_blank' : ''}`}
        rel={`${page.isExternal ? 'noopener noreferrer nofollow' : ''}`}
      >
        {page.anchor}
      </a>
    </div>
  ));

  const productPagesNav = productDataArr.map((page, index) => (
    <div key={index} className='product__item bottom-menu__list-item'>
      <a
        href={page.url}
        className='product__item-text bottom-menu__list-item-link'
        target={`${page.isExternal ? '_blank' : ''}`}
        rel={`${page.isExternal ? 'noopener noreferrer nofollow' : ''}`}
      >
        {page.anchor}
      </a>
    </div>
  ));

  const solutionsPagesNav = solutionsDataArr.map((page, index) => (
    <div key={index} className='solutions__item bottom-menu__list-item'>
      <Link href={page.url}>
        <a className='solutions__item-text bottom-menu__list-item-link'>
          {page.anchor}
        </a>
      </Link>
    </div>
  ));

  return (
    <section className='bottom-menu'>
      <div className='bottom-menu__container'>
        <div className='bottom-menu__company bottom-menu__list company'>
          <header className='company__header bottom-menu__list-header'>
            <p className='company__header-text header-text'>Company</p>
          </header>

          {companyPagesNav}
          <div className='company__icons-container'>{socialMediaIconsNav}</div>
        </div>
        <div className='bottom-menu__product bottom-menu__list product'>
          <header className='product__header bottom-menu__list-header'>
            <p className='product__header-text header-text'>Product</p>
          </header>
          {productPagesNav}
        </div>
        <div className='bottom-menu__solutions-bottom bottom-menu__list solutions-bottom '>
          <header className='solutions-bottom__header bottom-menu__list-header'>
            <p className='solutions-bottom__header-text header-text'>
              Solutions
            </p>
          </header>
          {solutionsPagesNav}
        </div>
        <div className='bottom-menu__contact bottom-menu__list contact'>
          <header className='contact__header bottom-menu__list-header'>
            <p className='contact__header-text header-text'>cux.io – HQ</p>
          </header>
          <div className='contact__item bottom-menu__list-item'>
            <p className='contact__item-text bottom-menu__list-item-text'>
              Fabryczna 6
            </p>
            <p className='contact__item-text bottom-menu__list-item-text'>
              53-609 Wrocław, Poland
            </p>
          </div>
          <p className='contact__header-text header-text header-text--second-adress'>
            cux.io - offices
          </p>
          <div className='contact__item bottom-menu__list-item'>
            <p className='contact__item-text bottom-menu__list-item-text'>
              Lohmühlenstraße 65
            </p>
            <p className='contact__item-text bottom-menu__list-item-text'>
              12435 Berlin, Germany
            </p>
          </div>
          <div className='contact__item bottom-menu__list-item bottom-menu__list-item--second-adress'>
            <p className='contact__item-text bottom-menu__list-item-text'>
              Rua do Crucifixo 40,
            </p>
            <p className='contact__item-text bottom-menu__list-item-text'>
              1100-183 Lisboa
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomMenu;
